import React, { useEffect, useState } from "react";
import "./Message.css";
import ChatIcon from "./ChatIcon";
import haisLogo from "../images/logo.svg";
import userLogo from "../images/medico.png";
import ReactMarkdown from "react-markdown";
import { useMessages } from "../contexts/MessageProvider";

function Message({ content, role, stream }) {
  const isUser = role === "user";
  const [displayedText, setDisplayedText] = useState("");
  const { setIsStreaming, setLastStreamMessage, setFinishedStream } = useMessages();

  useEffect(() => {
    if (content !== undefined && !isUser && stream) {
      let currentIndex = 0;
      let text = "";

      const intervalId = setInterval(() => {
        if (currentIndex < content.length) {
          text += content[currentIndex];
          if(content[currentIndex])
          setDisplayedText(text);
          currentIndex++;
          
          setIsStreaming(true);
          setFinishedStream(currentIndex === content.length);
          setLastStreamMessage(content[currentIndex]);

        } else {
          clearInterval(intervalId);
          setIsStreaming(false);

        }
      }, 20); // Tempo entre caracteres

      return () => clearInterval(intervalId);

    } else {
      setDisplayedText(content);
      setIsStreaming(false);

    }
   // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [content, isUser]);

  return (
    <div
      className={`chat-item ${isUser ? "user-chat-item" : "assistant-chat-item"}`}
    >
      <div className={`message ${isUser ? "" : "assistant-message"}`}>
        {isUser ? (
          <>
            <div>{displayedText}</div>
            <div className="chat-icon">
              <ChatIcon src={userLogo} alt="Ícone do usuário" user={true} />
            </div>
          </>
        ) : (
          <>
            <div className="chat-icon">
              <ChatIcon src={haisLogo} alt="Ícone do assistente" user={false} />
            </div>
            <div className="assistant-text-message">
              <ReactMarkdown>{displayedText}</ReactMarkdown>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Message;
